import SliderMain from '../../components/other/SliderMain';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox.css';
import './product.scss';

const Product = () => {
    Fancybox.bind('[data-fancybox]', {
        autoDimensions: false,
    });
    const slider = SliderMain({
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
    });

    slider.on('slideChange', function () {
        console.log(222, 'doit');
    });
};

Product();
